import React, { FC } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import If from 'bundles/components/common/If';
import { URL } from 'bundles/constants';

import './styles.scss';

const PublicFooter = ({ is_public_pages, current_year }:{is_public_pages: string; current_year: string}) => {
  const { t, i18n } = useTranslation();

  return (
    <footer className="public-footer">
      <Container>
        <If condition={is_public_pages}>
          <Row className="mb-6">
            <Col sm={6} lg={3} className="mt-6">
              <h3> {t('public.titles.resources')}</h3>
              <ul>
                <li>
                  <a href={`/${i18n.language}${URL.FEES_LIMITS}`} className="mr-3">{t('titles.fees')}</a>
                </li>
                <li>
                  <a href={URL.SUPPORT} className="mr-3" target="_blank" rel="noreferrer">{t('public.labels.support')}</a>
                </li>
                <li>
                  <a href={URL.API} className="mr-3" target="_blank" rel="noreferrer">{t('public.labels.api')}</a>
                </li>
                <li>
                  <a href={`/${i18n.language}${URL.FAQ}`} className="mr-3">{t('public.faq.title')}</a>
                </li>
                <li>
                  <a href={`/${i18n.language}${URL.MERCHANT_INTEGRATION_GUIDE}`} className="mr-3" target="_blank" rel="noreferrer">{t('public.labels.merchant_integration_guide')}</a>
                </li>
                <li>
                  <a href={`${URL.STATUS_PAGE}`} className="mr-3" target="_blank" rel="noreferrer">{t('public.labels.status_page')}</a>
                </li>
              </ul>
            </Col>

            <Col sm={6} lg={3} className="mt-6">
              <h3> {t('public.titles.legal')}</h3>
              <ul>
                <li>
                  <a href={`/${i18n.language}${URL.COMPLAINTS_HANDLING_POLICY}`} className="mr-3" target="_blank" rel="noreferrer">{t('public.labels.complaints_handling_policy')}</a>
                </li>
                <li>
                  <a href={`/${i18n.language}${URL.COOKIE_POLICY}`} className="mr-3" target="_blank" rel="noreferrer">{t('public.labels.cookie_policy')}</a>
                </li>
                <li>
                  <a href={`/${i18n.language}${URL.COOKIE_SETTINGS}`} className="mr-3" rel="noreferrer">{t('public.cookie_settings.title')}</a>
                </li>
                <li>
                  <a href={`/${i18n.language}${URL.PRIVACY_POLICY}`} className="mr-3" target="_blank" rel="noreferrer">{t('labels.privacy_policy')}</a>
                </li>
                <li>
                  <a href={`/${i18n.language}${URL.TERMS_OF_USE}`} className="mr-3" target="_blank" rel="noreferrer">{t('labels.terms_of_use')}</a>
                </li>
                <li>
                  <a href={`/${i18n.language}${URL.DOCUMENTATION_HISTORY}`} className="mr-3">{t('public.labels.documentation_history')}</a>
                </li>
                <li>
                  <a href={`/${i18n.language}${URL.CONSUMERS_TERMS_AND_CONDITIONS}`} className="mr-3" target="_blank" rel="noreferrer">{t('public.labels.consumer_terms_and_conditions')}</a>
                </li>
                {/* should be uncommented with the launch of the new licence */}
                {/* <li>
                  <a href={`/${i18n.language}${URL.BUSINESS_TERMS_AND_CONDITIONS_IOM}`} className="mr-3" target="_blank" rel="noreferrer">{t('public.labels.business_terms_and_conditions_iom')}</a>
                </li> */}
                <li>
                  <a href={`/${i18n.language}${URL.BUSINESS_TERMS_AND_CONDITIONS}`} className="mr-3" target="_blank" rel="noreferrer">{t('public.labels.business_terms_and_conditions')}</a>
                </li>
                <li>
                  <a href={`/${i18n.language}${URL.MODERN_SLAVERY_AND_HUMAN_TRAFFICKING_STATEMENT}`} className="mr-3" target="_blank" rel="noreferrer">{t('public.labels.modern_slavery_statement')}</a>
                </li>
                <li>
                  <a href={`/${i18n.language}${URL.SECURITY_OF_CLIENT_FUNDS}`} className="mr-3" target="_blank" rel="noreferrer">{t('public.labels.security_of_client_funds')}</a>
                </li>
              </ul>
            </Col>

            <Col sm={6} lg={3} className="mt-6">
              <h3> {t('public.titles.social_media')}</h3>
              <ul>
                <li>
                  <a href={URL.FACEBOOK} className="mr-3" target="_blank" rel="noreferrer">{t('public.labels.facebook')}</a>
                </li>
                <li>
                  <a href={URL.TWITTER} className="mr-3" target="_blank" rel="noreferrer">{t('public.labels.twitter')}</a>
                </li>
              </ul>
            </Col>

            <Col sm={6} lg={3} className="mt-6">
              <h3> {t('public.titles.our_programmes')}</h3>
              <ul>
                <li>
                  <a href={`/${i18n.language}${URL.EZEEWALLET_VIP_PROGRAMME}`} className="mr-3" target="_blank" rel="noreferrer">{t('public.labels.vip_programme')}</a>
                </li>
                <li>
                  <a href={`/${i18n.language}${URL.VIP_TERMS_AND_CONDITIONS}`} className="mr-3" target="_blank" rel="noreferrer">{t('public.labels.vip_terms_and_conditions')}</a>
                </li>
                <li>
                  <a href={`/${i18n.language}${URL.REFER_A_FRIEND}`} className="mr-3" target="_blank" rel="noreferrer">{t('public.labels.refer_friend')}</a>
                </li>
                <li>
                  <a href={`/${i18n.language}${URL.AFFILIATE}`} className="mr-3" target="_blank" rel="noreferrer">{t('public.labels.affiliate_programme')}</a>
                </li>
              </ul>
            </Col>
          </Row>
        </If>
        <div className="copyright text-center text-gray mb-4">
          {t('public.footer.copyright_content', { current_year: current_year })}
        </div>
      </Container>
    </footer>
  );
};

const PublicFooterWrapper: FC<{is_public_pages: string; current_year: string}> = props => <PublicFooter {...props} />;

export default PublicFooterWrapper;
